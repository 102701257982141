<template>
  <div>
    <b-alert variant="danger" :show="error.length > 0">
      <h4 class="alert-heading">Error obteniendo datos</h4>
      <div class="alert-body">
        Ocurrio un error al obtener los Productos Market {{ error }}
      </div>
    </b-alert>

    <b-card title="Productos Market">
      <b-row class=" mt-2 ">
        <b-col cols="12" md="3" class="mb-2">
          <b-button variant="primary" @click="orderCompositeProduct($event)"> Producto Compuesto </b-button>
        </b-col>
        <b-col cols="12" md="3" class="mb-2">
          <b-button variant="primary" @click="orderProduct" style="width: 195.78px;"> Ordenar </b-button>
        </b-col>
        <b-col cols="12" md="3">
          <b-button variant="primary" @click="promtionsProduct" style="width: 195.78px;"> Promociones </b-button>
        </b-col>
      </b-row>
      <div class="custom-search d-flex justify-content-end mt-2">
        <b-form-group class="px-1">
          <b-form-select 
            v-model="typeProduct" 
            :options="optionsTypeProduct"
            @change="getProductosMarket"
          ></b-form-select>
        </b-form-group>
        <b-form-group>
          <div class="d-flex align-items-center">
            <label class="mr-1">Buscar</label>
            <b-form-input
              type="text"
              class="d-inline-block"
              @input="onSearch"
              placeholder="Buscar"
            />
          </div>
        </b-form-group>
      </div>

      <!-- table -->
      <vue-good-table
        mode="remote"
        :rows="rows"
        :columns="columns"
        :totalRows="totalRecords"
        :isLoading.sync="loading"
        @on-page-change="onPageChange"
        @on-per-page-change="onPerPageChange"
        :sort-options="{
          enabled: false,
        }"
        :search-options="{
          enabled: true,
          externalQuery: searchTerm,
        }"
        :pagination-options="{
          enabled: true,
          perPage: pageLength,
        }"
      >
        <template slot="emptystate"
          >No hay productos Market para mostrar</template
        >
        <template slot="table-row" slot-scope="props">
          <!-- Column: Tipo -->
          <span v-if="props.column.field === 'tipoProducto'">
            <b-badge :variant="typeVariant(props.row.tipoProducto)">
              {{ typeText(props.row.tipoProducto) }}
            </b-badge>
          </span>

          <!-- Column: Action -->
          <span v-else-if="props.column.field === 'action'">
            <span>
              <b-dropdown
                variant="link"
                toggle-class="text-decoration-none"
                no-caret
              >
                <template v-slot:button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="16"
                    class="text-body align-middle mr-25"
                  />
                </template>
                <b-dropdown-item @click="editProduct($event, props.row)">
                  <feather-icon icon="Edit2Icon" class="mr-50" />
                  <span>Editar</span>
                </b-dropdown-item>
                <b-dropdown-item @click="stockProduct($event, props.row)">
                  <feather-icon icon="ArchiveIcon" class="mr-50" />
                  <span>Ver Stock</span>
                </b-dropdown-item>
              </b-dropdown>
            </span>
          </span>

          <!-- Column: Otras Columnas -->
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>

        <!-- pagination -->
        <template slot="pagination-bottom" slot-scope="props">
          <div class="d-flex justify-content-between flex-wrap">
            <div class="d-flex align-items-center mb-0 mt-1">
              <span class="text-nowrap"> Cantidad de productos </span>
              <b-form-select
                v-model="pageLength"
                :options="['10', '15', '20']"
                class="mx-1"
                @input="
                  (value) => props.perPageChanged({ currentPerPage: value })
                "
              />
            </div>
            <div>
              <b-pagination
                align="right"
                class="mt-1 mb-0"
                @input="(value) => props.pageChanged({ currentPage: value })"
                :value="1"
                :per-page="pageLength"
                last-number
                :total-rows="props.total"
                first-number
                prev-class="prev-item"
                next-class="next-item"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </div>
          </div>
        </template>
      </vue-good-table>
      <b-modal
        title="Stock"
        ok-only
        v-model="showStockModal"
        centered
        ok-title="Aceptar"
        ok-variant="primary"
      >
        <div v-if="loadingStock" class="loader">
          <b-spinner />
        </div>
        <b-table-lite v-else hover :items="productStock" />
      </b-modal>
      <b-modal
        title="Error"
        ok-only
        v-model="showErrorModal"
        centered
        ok-title="Aceptar"
        ok-variant="danger"
        modal-class="modal-danger"
      >
        <b-card-text>
          {{ error }}
        </b-card-text>
      </b-modal>
      <b-modal
          title="Carga Masiva Promociones"
          ok-only
          v-model="showPromotionModal"
          centered
          ok-variant="success"
          modal-class="modal-success"
          hide-footer
          size="lg"
      >
        <b-card-text>
          <CargaMasivaPromociones/>
        </b-card-text>
      </b-modal>
    </b-card>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BAlert,
  BBadge,
  BAvatar,
  BSpinner,
  BDropdown,
  BCardText,
  BFormGroup,
  BFormInput,
  BTableLite,
  BFormSelect,
  BPagination,
  BDropdownItem,
  BButton,
} from "bootstrap-vue";
import moment from "moment";
import { getAuthToken } from "@/auth/utils";
import { VueGoodTable } from "vue-good-table";
import CargaMasivaPromociones from "./components/CargaMasivaPromociones.vue";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BAlert,
    BBadge,
    BButton,
    BAvatar,
    BSpinner,
    BDropdown,
    BCardText,
    BFormGroup,
    BFormInput,
    BTableLite,
    BPagination,
    BFormSelect,
    VueGoodTable,
    BDropdownItem,
    BButton,
    CargaMasivaPromociones
  },
  data() {
    return {
      page: 1,
      rows: [],
      error: "",
      loading: true,
      searchTerm: "",
      pageLength: 10,
      totalRecords: 0,
      productStock: [],
      seachTimeout: null,
      loadingStock: true,
      showStockModal: false,
      showErrorModal: false,
      showPromotionModal: false,
      typeProduct: null,
      columns: [
        {
          label: "SKU",
          field: "sku",
        },
        {
          label: "Nombre",
          field: "nombre",
        },
        {
          label: "Tipo",
          field: "tipoProducto",
        },
        {
          label: "Action",
          field: "action",
        },
      ],
      optionsTypeProduct: [
        { value: null, text: "Escoja tipo de productos", disabled: true},
        { value: "COM", text: "COMPUESTO" },
        { value: "SIM", text: "SIMPLE" }
      ]
    };
  },
  mounted() {
    this.getProductosMarket();
  },
  methods: {
    onSearch(query) {
      this.loading = true;
      this.searchTerm = query;
      clearTimeout(this.seachTimeout);
      this.seachTimeout = setTimeout(() => {
        this.page = 1;
        this.getProductosMarket();
      }, 2500);
    },
    onPageChange(params) {
      this.page = params.currentPage;
      this.getProductosMarket();
    },
    onPerPageChange(params) {
      this.pageLength = params.currentPerPage;
      this.getProductosMarket();
    },
    formattedDate(originalDate) {
      return moment(originalDate).format("DD/MM/YYYY");
    },
    formattedHour(originalDate) {
      return moment(originalDate).format("hh:mm a");
    },
    getProductosMarket() {
      this.loading = true;
      this.$http
        .post(this.$store.state.app.middlewareURL, {
          path: "/Productos/ConsultaProductosAdmin",
          body: JSON.stringify({
            Filas: this.pageLength,
            Pagina: this.page,
            LineaDeNegocio: "MARKET",
            NombreProducto: this.searchTerm,
            TipoProd: this.typeProduct,
            tokenSesion: getAuthToken(),
          }),
        })
        .then((res) => {
          this.loading = false;
          if (res.data.bOk) {
            this.rows = res.data.lstProductos;
            this.totalRecords = res.data.totalPaginas;
          } else {
            this.error = res.data.mensaje;
            this.showErrorModal = true;
          }
        })
        .catch((e) => {
          this.loading = false;
          this.error = e.message;
          this.showErrorModal = true;
        });
    },
    orderCompositeProduct(event) {
      event && event.preventDefault();
      this.$router.push({
        name: "productos-compuestos",
      });
    },
    editProduct(event, product) {
      event && event.preventDefault();
      this.$router.push({
        name: "producto-edit",
        params: { id: product.id },
      });
    },
    orderProduct() {
      this.$router.push({
        name: "producto-ordenar",
      });
    },
    promtionsProduct() {
      this.showPromotionModal = true
    },
    stockProduct(event, product) {
      event && event.preventDefault();
      this.productSelected = product;
      this.getProductStock(product.id);
      this.showStockModal = true;
    },
    getProductStock(id) {
      this.loadingStock = true;
      this.$http
        .post(this.$store.state.app.middlewareURL, {
          path: "/Productos/BodegaProductos",
          body: JSON.stringify({
            Empresa: 2,
            IDProducto: id,
            tokenSesion: getAuthToken(),
          }),
        })
        .then((res) => {
          if (res.data.bOk) {
            this.productStock = res.data.listBodegaProductos.map((e) => ({
              Bodega: e.nombreBodega,
              Stock: e.stock,
            }));
          } else {
            throw new Error(res.data.mensaje);
          }
        })
        .catch((e) => {
          this.errorStock = e.message;
        })
        .finally(() => {
          this.loadingStock = false;
        });
    },
  },
  computed: {
    typeText() {
      const type = {
        SIM: "Simple",
        COP: "Compuesto",
        SAM: "Sampling",
      };
      return (s) => type[s];
    },
    typeVariant() {
      const typeColor = {
        SIM: "light-success",
        COP: "light-warning",
        SAM: "light-danger",
      };
      return (s) => typeColor[s];
    },
  },
};
</script>

<style lang="scss" >
table.vgt-table {
  font-size: 13px !important;
}
.loader {
  height: 20vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>
