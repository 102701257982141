<template>
    <div>  
        <div>
            <b-form-file
              id="input"
              @change="changeFile"
              accept=".xlsx,.xls"
              placeholder="seleccione archivo .xlsx"
              drop-placeholder="Drop file here..."
              :class="[error.currentFile ? 'is-invalid' : '']"
            ></b-form-file>
            <div v-if="error.currentFile" class="invalid-feedback">
                Estructura de archivo no valido 
            </div>
            <b-overlay :show="load" rounded="sm">
                <b-table
                    :items="rows"
                    responsive
                    :fields="tableColumns"
                    show-empty
                    empty-text="No matching records found"
                    class="position-relative mt-2"
                >
                    <template #cell(#)="data">
                        {{ data.index + 1 }}
                    </template>
                    <template #cell(sku)="data">
                        {{ data.item.sku }}
                    </template>
                    <template #cell(type)="data">
                        {{ data.item.type }}
                    </template>
                    <template #cell(discount)="data">
                        {{ data.item.discount }}
                    </template>
                    <template #cell(fechaIni)="data">
                        {{ data.item.fechaIni }}
                    </template>
                    <template #cell(fechaFin)="data">
                        {{ data.item.fechaFin }}
                    </template>
                    <template #cell(horaIni)="data">
                        {{ data.item.horaIni }}
                    </template>
                    <template #cell(horaFin)="data">
                        {{ data.item.horaFin }}
                    </template>
                    <template #cell(freeDelivery)="data">
                        {{ data.item.freeDelivery }}
                    </template>
                </b-table>
            </b-overlay>
            <b-button variant="primary" @click="saveProductsDiscont" :disabled="disabled">
                Guardar
            </b-button>
        </div>
    </div>
  </template>


<script>

  import {
    BFormFile,
    BOverlay,
    BTable,
    BButton
  } from 'bootstrap-vue' 

  import moment from "moment";
  import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
  import { getAuthToken } from "@/auth/utils";

  import readXlsxFile from 'read-excel-file'

  export default {
    components: {
        BFormFile,
        BOverlay,
        BTable,
        BButton,
        ToastificationContent,
    },
    data() {
        return {
            load: false,
            disabled: true,
            schema: {
                'Sku': {
                    prop: "sku",
                    required: true,
                    type: (value) => this.validSKU(value),                
                },
                'Tipo de promoción': {
                    prop: "type",
                    type: (value) => this.validType(value),
                    required: true
                },
                'descuento': {
                    prop: "discount",
                    type: (value) => this.validIsNumber(value),
                },
                'Fecha inicio': {
                    prop: "fechaIni",
                    type: (value) => this.validIsDate(value),
                    required: true
                },
                'Fecha fin': {
                    prop: "fechaFin",
                    type: (value) => this.validIsDate(value),
                    required: true
                },
                'Hora inicio': {
                    prop: "horaIni",
                    type: String,
                    required: false
                },
                'Hora fin': {
                    prop: "horaFin",
                    type: String,
                    required: false
                },
                'Entrega Gratis': {
                    prop: "freeDelivery",
                    type: (value) => {
                        if (value != 0 && value != 1)
                            throw new Error('invalid')
                        return value
                    }
                }
            },
            rows: [],
            data: {
                pomotionProducts: []
            },
            error: {
                currentFile: false
            },
            tableColumns: [
                '#',
                { key: "sku", label: "SKU"},
                { key: "type", label: "Tipo de Promocion"},
                { key: "discount", label: "Descuento"},
                { key: "fechaIni", label: "Fecha inicio"},
                { key: "fechaFin", label: "Fecha fin"},
                { key: "horaIni", label: "Hora Inicio"},
                { key: "horaFin", label: "Hora fin"},
                { key: "freeDelivery", label: "Entrega gratis"},
            ]
        }
    },
    mounted() {},
    methods: {
        changeFile(element) {
            this.load = true
            const schema = this.schema
            const currentFile = element.currentTarget.files
            readXlsxFile(currentFile[0],{ schema }).then(({ rows, errors }) => {
                this.error.currentFile = errors.length !== 0 || rows.length === 0
                this.load = false

                if ( this.error.currentFile )
                  return 
                
                this.disabled = false

                this.rows = rows.map( r => {
                    const {
                        sku,
                        type,
                        discount,
                        fechaIni,
                        fechaFin,
                        horaIni,
                        horaFin,
                        freeDelivery
                    } = r
                    
                    let percentage = 0
                    let value =  0
                    
                    if ( type === '01' ) 
                        percentage = discount
                    
                    if ( type === '02' )
                        value = discount
                    
                    let auxType = type

                    if ( freeDelivery === '1' )
                        auxType = '03'
                    
                    let AuxFechaIni = moment(fechaIni);
                    let AuxFechaFin = moment(fechaFin);
                    const AuxHoraIni = moment(horaIni, "HH:mm:ss");
                    const AuxHoraFin = moment(horaFin, "HH:mm:ss");
                    
                    AuxFechaIni.set({
                        hour: AuxHoraIni.get("hour"),
                        minute: AuxHoraIni.get("minute"),
                        second: AuxHoraIni.get("second"),
                        millisecond: 0,
                    });

                    AuxFechaFin.set({
                        hour: AuxHoraFin.get("hour"),
                        minute: AuxHoraFin.get("minute"),
                        second: AuxHoraFin.get("second"),
                        millisecond: 0,
                    });

                    AuxFechaIni = moment(AuxFechaIni).format("YYYY-MM-DDTHH:mm:ss");
                    AuxFechaFin = moment(AuxFechaFin).format("YYYY-MM-DDTHH:mm:ss");

                    const productoSku = sku
                    const descuento = {
                            tipo: auxType,
                            valor: value,
                            porcentaje: percentage,
                            fechaIni: AuxFechaIni,
                            fechaFin: AuxFechaFin,
                            id: 0,
                            precioBajo: false,
                            estado: true
                        }
                    this.data.pomotionProducts.push({
                        productoSku,
                        descuento
                    })
                    return {
                        ...r
                    }
                })
            })
        },
        saveProductsDiscont() {
            if ( !this.error.currentFile ) {
                this.load = true
                this.$http
                .post(this.$store.state.app.middlewareURL, {
                    path: "/Administracion/BulkLoadPromotions",
                    body:  JSON.stringify({
                        tokenSesion: getAuthToken(),
                        Items: this.data.pomotionProducts
                    }),
                })
                .then((res) => {
                    this.load = false
                    if (res.data.bOk) {
                        this.$toast({
                            component: ToastificationContent,
                            props: {
                                icon: "CheckIcon",
                                text: res.data.mensaje,
                                title: "Exito",
                                variant: "success",
                            },
                        });
                        this.rows = []
                    } else {
                        this.$toast({
                            component: ToastificationContent,
                            props: {
                                title: "Error",
                                icon: "AlertTriangleIcon",
                                text: res.data.mensaje,
                                variant: "danger",
                            },
                        });
                        this.error = res.data.mensaje;
                        this.showErrorModal = true;
                    }
                })
                .catch((e) => {
                    this.load = false
                    this.error = e.message;
                    this.showErrorModal = true;
                });
                this.load = false
            }
        },
        validIsNumber (value) {
            const num = new Number(value)
            if (!(num instanceof Number))
                throw new Error('invalid')
            return  Number(value)
        },
        validIsDate(value) {
            const dateAux = value.split('-')
            if (dateAux.length !== 3) 
                throw new Error('invalid')
            
            const [ year, month, day ] = dateAux

            
            if ( year.length != 4)
                throw new Error('invalid')
            
            if ( month.length !== 2 )
                throw new Error('invalid')
            
            if ( day.length != 2 )
                throw new Error('invalid')
            
            return value
        },
        validSKU(value) {
            if (value.length < 2 ) {
                throw new Error('invalid')
            }
            return value
        },
        validType(value) {
            if ( value != "01" && value != "02" && value != "03" ) {
                throw new Error('invalid')
            }
            return value
        }
    }
  }
</script>